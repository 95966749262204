<template>
  <v-main>
    <v-img 
      :src="require('@/assets/home.jpg')"
      height="30vh"
    />
    <v-container style=""></v-container>
    <v-container>
      <v-row justify="center"><h1>ニュース</h1></v-row>
      <v-row justify="center" class="my-5">
        <v-card color="white" flat width="90%">
          <v-card-text v-for="n in news" :key="n.id">
            <pre>{{ convertDataString(n.created_at) }}        {{ n.content }}</pre>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row justify="center"><h1>匠ランキング</h1></v-row>
      <v-row justify="center" class="my-5">
        <v-card color="white" flat width="90%">
          <v-card-title>
            <div>週間ランキング</div>
          </v-card-title>
          <v-card-subtitle>
            <div>最終更新 {{ this.updatedAt }}</div>
          </v-card-subtitle>
          <v-card-text>
            <v-data-table
              calculate-widths
              :loading="loading"
              loading-text="読み込み中です"
              :headers="rankingHeaders"
              :items="weeklyRanking"
              item-key="id"
              style="width: 95%"
              class="table-props"
            >
              <template v-slot:item.id="{ item }">
                <v-icon v-if="item.id == 1" color="#edcf26">mdi-crown</v-icon>
                <v-icon v-if="item.id == 2" color="#dedede">mdi-crown</v-icon>
                <v-icon v-if="item.id == 3" color="#bb610c">mdi-crown</v-icon>
                <span>{{ item.id }}位</span>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-title>月間ランキング</v-card-title>
          <v-card-subtitle>
            <div>最終更新 {{ this.updatedAt }}</div>
          </v-card-subtitle>
          <v-card-text>
            <v-data-table
              calculate-widths
              :loading="loading"
              loading-text="読み込み中です"
              :headers="rankingHeaders"
              :items="monthlyRanking"
              item-key="id"
              style="width: 95%"
              class="table-props"
            >
              <template v-slot:item.id="{ item }">
                <v-icon v-if="item.id == 1" color="#edcf26">mdi-crown</v-icon>
                <v-icon v-if="item.id == 2" color="#dedede">mdi-crown</v-icon>
                <v-icon v-if="item.id == 3" color="#bb610c">mdi-crown</v-icon>
                <span>{{ item.id }}位</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-row>

    </v-container>


    <!-- loading -->
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>

  </v-main>
</template>

<script>
import axios from "axios";

export default {
  name: 'Home',

  data: () => ({
    loading: false,
    showLogOutConfirm: false,
    handleName: "",
    updatedAt: "",
    monthlyRanking: [],
    weeklyRanking: [],
    rankingHeaders: [
      {text: "順位", value: "id"},
      {text: "ユーザー名", value: "handle_name"},
      {text: "偏差平均", value: "diff"},
      {text: "R2", value: "r2"},
      {text: "相対RMSE", value: "rrmse"},
      {text: "傾き", value: "coef"},
      {text: "切片", value: "intercept"},
      {text: "レコード数", value: "count"},
    ],
    news: []
  }),
  computed: {
  },
  components: {
  },
  methods: {
    get_takumi_ranking() {
      this.loading = true
      var url = process.env.VUE_APP_API_ORIGIN + "/api/takumi_ranking/get"
      const headers = {
        'content-type': 'application/json',
        'x-line-access-token': this.$store.state.auth.lineAccessToken
      }
      axios({
        method: "GET",
        url: url,
        withCredentials: true,
        headers: headers,
        timeout: parseInt(process.env.VUE_APP_API_TIMEOUT_MILLISEC)
      })
      .then(res => {
        var monthlyRanking = []
        res.data.month.records.forEach((rec, i) => {
          rec.id = i+1
          rec.diff = Math.round(rec.diff * 10)/10
          rec.r2 = Math.round(rec.r2 * 100) / 100
          rec.rrmse = Math.round(rec.rrmse * 100) / 100
          rec.coef = Math.round(rec.coef * 100) / 100
          rec.intercept = Math.round(rec.intercept * 100) / 100
          monthlyRanking.push(rec)
        })
        var weeklyRanking = []
        res.data.week.records.forEach((rec, i) => {
          rec.id = i+1
          rec.diff = Math.round(rec.diff * 10)/10
          rec.r2 = Math.round(rec.r2 * 100) / 100
          rec.rrmse = Math.round(rec.rrmse * 100) / 100
          rec.coef = Math.round(rec.coef * 100) / 100
          rec.intercept = Math.round(rec.intercept * 100) / 100
          weeklyRanking.push(rec)
        })
        this.monthlyRanking = monthlyRanking
        this.weeklyRanking = weeklyRanking
        this.updatedAt = this.convertDataString(res.data.month.updated_at),
        this.loading = false
      })
      .catch(error => {
        if (error.response) {
          this.errorTitle = String(error.response.status)
          this.errorMessage = error.response.data.detail
        } else {
          this.errorTitle = "エラー"
          this.errorMessage = error.message
        }
        this.showError = true
        this.loading = false
      });
    },
    convertDataString(dateStr) {
      let date = new Date(dateStr)
      return date.toLocaleString()
    },
    getNews() {
      this.loading = true
      var url = process.env.VUE_APP_API_ORIGIN + "/api/news/get"
      const headers = {
        'content-type': 'application/json',
        'x-line-access-token': this.$store.state.auth.lineAccessToken
      }
      axios({
        method: "GET",
        url: url,
        withCredentials: true,
        headers: headers,
        timeout: parseInt(process.env.VUE_APP_API_TIMEOUT_MILLISEC)
      })
      .then(res => {
        var news = []
        res.data.forEach((rec, i) => {
          rec.id = i+1
          news.push(rec)
        })
        this.news = news
        this.loading = false
      })
      .catch(error => {
        if (error.response) {
          this.errorTitle = String(error.response.status)
          this.errorMessage = error.response.data.detail
        } else {
          this.errorTitle = "エラー"
          this.errorMessage = error.message
        }
        this.showError = true
        this.loading = false
      });
    }
  },
  created() {
    this.get_takumi_ranking()
    this.getNews()
  },
}
</script>